<template>
  <form @submit.prevent="createSchedule()" action="">
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item v-if="validationError" lines="none">
            <ion-label className="ion-text-wrap">
              <ion-text color="danger">
                <p v-for="error of v$.$errors" :key="error.$uid">
                  <ion-icon
                    :ios="alertCircleOutline"
                    :md="alertCircleSharp"
                    size="small"
                    style="vertical-align: middle"
                  ></ion-icon
                  >{{ error.$property }}: {{ error.$message }}
                </p></ion-text
              >
            </ion-label>
          </ion-item>
          <ion-item v-if="this.exam">
            <ion-label position="floating">Exam date</ion-label>
            <ion-datetime v-model="exam_date" :min="min_date" :max="max_date"></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Start</ion-label>
            <ion-datetime
              display-format="h:mm A"
              picker-format="h:mm A"
              v-model="start"
            ></ion-datetime>
          </ion-item>
          <ion-item>
            <ion-label position="floating">End</ion-label>
            <ion-datetime
              display-format="h:mm A"
              picker-format="h:mm A"
              v-model="end"
            ></ion-datetime>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button type="submit" expand="full" class="ion-margin-vertical">
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonList,
  IonLabel,
  IonItem,
  IonIcon,
  IonButton,
  IonText,
  IonDatetime,
  loadingController,
  alertController,
} from "@ionic/vue";

import { alertCircleOutline, alertCircleSharp } from "ionicons/icons";

import { required, helpers } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";

export default {
  emits: ["closeModal"],
  props: ["exam_id"],
  components: {
    IonRow,
    IonCol,
    IonList,
    IonLabel,
    IonItem,
    IonIcon,
    IonButton,
    IonText,
    IonDatetime,
  },
  data() {
    return {
      name: null,
      validationError: null,

      min_date: null,
      max_date: null,

      exam_date: null,
      start: null,
      end: null,

      alertCircleOutline,
      alertCircleSharp,

      xhrError: {
        header: null,
        description: null,
      },
    };
  },

  created() {
    this.fetchExam();
  },

  computed: {
    exam() {
      return this.$store.getters["exam/specificResource"];
    },
    formated_exam_date() {
      return moment(this.exam_date).format("YYYY-MM-DD");
    },

    start_time() {
      let formated_exam_date = this.formated_exam_date;
      let formated_start_time = moment(this.start).format("HH:mm");
      return formated_exam_date + " " + formated_start_time;
    },

    end_time() {
      let formated_exam_date = this.formated_exam_date;
      let formated_end_time = moment(this.end).format("HH:mm");
      return formated_exam_date + " " + formated_end_time;
    },
  },

  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    return {
      start: {
        required: helpers.withMessage("cannot be empty", required),
      },
      end: {
        required: helpers.withMessage("cannot be empty", required),
        minValue: helpers.withMessage(
          "cannot be less than start time",
          function minValue(val) {
            return new Date(val) >= new Date(this.start);
          }
        ),
      },
    };
  },

  methods: {
    setModalState(state) {
      this.modalState = state;
    },

    loader(message) {
      const loading = loadingController.create({
        message: message,
        backdropDismiss: false,
      });

      return loading;
    },

    alerter(header, message, buttons = ["close"]) {
      const alert = alertController.create({
        header: header,
        message: message,
        buttons: buttons,
      });

      return alert;
    },

    setErrorResponse(error) {
      if (error.response) {
        this.xhrError.header = error.response.data.header;
        this.xhrError.message = error.response.data.message;
      } else if (error.request) {
        this.xhrError.header = error.message || error.request;
        this.xhrError.message = "Please check your connection and try again";
      } else {
        this.xhrError.header = error.message;
        this.xhrError.message = "Something went wrong. Try again later";
      }
    },

    async createSchedule() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.validationError = true;
        return;
      }

      let data = {
        exam_id: this.exam_id,
        start: this.start_time,
        end: this.end_time,
      };

      const spinner = await this.loader("Saving...");
      spinner.present();

      try {
        await this.$store.dispatch("examSchedule/createResource", data);
        spinner.dismiss();
        this.$emit("closeModal");
      } catch (error) {
        spinner.dismiss();
        this.setErrorResponse(error);
        const alertDialog = await this.alerter(
          this.xhrError.header,
          this.xhrError.message
        );
        alertDialog.present();
        this.validationError = null;
      }
    },

    async fetchExam() {
      await this.$store.dispatch("exam/specificResource", this.exam_id);
      this.min_date = moment(this.exam.exam_start_date).format("YYYY-MM-DD");
      this.max_date = moment(this.exam.exam_end_date).format("YYYY-MM-DD");
    },
  },
};
</script>